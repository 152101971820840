import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import { graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import Layout from 'components/layout';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ContainerDefault } from 'components/containers';
import { Application } from 'components/ui';

function Job(props) {
  const { data } = props;
  const unescapeHTML = html => {
    if (typeof window === 'undefined') {
      return;
    }
    var escapeEl = document.createElement('div');
    escapeEl.innerHTML = html;
    return escapeEl.textContent;
  };

  return (
    <Layout headerOpacity={1}>
      <Box pt={[7, 7, 7, 14]}>
        <ContainerDefault>
          <Box mb={8}>
            <Box
              component="ul"
              display="flex"
              alignItems="center"
              p={0}
              sx={{ listStyle: 'none' }}
            >
              <Box component="li">
                <Typography
                  component={Link}
                  variant="body2"
                  to="/open-jobs"
                  sx={{
                    fontWeight: 400,
                    display: 'block',
                    color: 'text.blueblack',
                  }}
                >
                  Open jobs
                </Typography>
              </Box>
              <Box
                component="li"
                mx={1}
                sx={{ background: '#8BA2C0', width: '2px', height: '12px' }}
                display={{ xs: 'none', sm: 'block' }}
              />
              {data.greenhouseJob.departments &&
                data.greenhouseJob.departments[0] && (
                  <Box component="li">
                    <Typography
                      component="p"
                      variant="body2"
                      sx={{ color: 'text.blueblack', fontWeight: 400 }}
                      display={{ xs: 'none', sm: 'block' }}
                    >
                      {data.greenhouseJob.departments[0]?.name}
                    </Typography>
                  </Box>
                )}
              <Box
                component="li"
                mx={1}
                sx={{ background: '#8BA2C0', width: '2px', height: '12px' }}
                display={{ xs: 'none', sm: 'block' }}
              />
              <Box component="li">
                <Typography
                  component="p"
                  variant="body2"
                  sx={{ color: 'text.blueblack', fontWeight: 400 }}
                  display={{ xs: 'none', sm: 'block' }}
                >
                  {data.greenhouseJob.title}
                </Typography>
              </Box>
            </Box>
          </Box>
          <div className="spaced-grid-wrapper">
            <Grid
              container
              spacing={10}
              flexDirection="row-reverse"
              justifyContent="space-between"
            >
              <Grid item xs={12} lg={4}>
                <Application
                  offices={data.greenhouseJob.offices}
                  departments={data.greenhouseJob.departments}
                  title={data.greenhouseJob.title}
                  jobId={data.greenhouseJob.gh_Id}
                />
              </Grid>
              <Grid item xs={12} lg={7}>
                <Typography component="h1" variant="h2-variant">
                  {data.greenhouseJob.title}
                </Typography>
                <Typography
                  className="greenhouse-richtext"
                  component="div"
                  variant="body1"
                  sx={{ '& > span': { fontWeight: '300 !important' } }}
                  mb={10}
                  mt={5}
                >
                  {ReactHtmlParser(unescapeHTML(data.greenhouseJob.content))}
                </Typography>
              </Grid>
            </Grid>
          </div>
        </ContainerDefault>
      </Box>
    </Layout>
  );
}

export default Job;

Job.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query($id: String!) {
    greenhouseJob(id: { eq: $id }) {
      id
      internal_job_id
      gh_Id
      title
      content
      departments {
        name
        gh_Id
      }
      offices {
        gh_Id
        location
        name
      }
    }
  }
`;
